import React from "react";
// Estilos
import Container from "./styled";
// Componentes
import Card from "./card";
// Librerías
import Slider from "infinite-react-carousel";
// Utils
import useWindowDimensions from "../../../utils/useWindowDimensions";

export default function Estrellas() {
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Tag Container */}
        <div id="tag-container">
          {/* Tag */}
          <div>
            <p>Ustedes</p>
          </div>
        </div>
        {/* Title */}
        <h3>
          Conocé la opinón de <span>nuestros clientes</span>
        </h3>
        {/* Estrellas Container */}
        <div id="estrellas-container">
          <img alt="Estrella" src="/images/home/estrellas/estrella.svg"></img>
          <img alt="Estrella" src="/images/home/estrellas/estrella.svg"></img>
          <img alt="Estrella" src="/images/home/estrellas/estrella.svg"></img>
          <img alt="Estrella" src="/images/home/estrellas/estrella.svg"></img>
          <img
            alt="Estrella"
            src="/images/home/estrellas/estrellaVacia.svg"
          ></img>
          <div>
            <h3>4.6</h3>
            <h4>
              <span>estrellas</span> reales<br></br> valoradas en Google
            </h4>
          </div>
        </div>
        <p id="texto">
          SeguroBici con un puntaje de 4.6 estrellas y más de 1000 reseñas es
          valorada auténticamente por muchos de sus clientes.
        </p>
        {/* Carousel */}
        <Slider
           arrows={false}
           arrowsBlock={false}
           autoplay={true}
           dots={false}
           duration={300}
          slidesToShow={
            width > 1710 ? 3 : width > 1200 ? 3 : width > 850 ? 2 : 1
          }
          wheel={false}
          className="carousel"
        >
          <Card
            imagen="/images//home/estrellas/slider/tamara.png"
            nombre="Tamara González"
            estrellas="5"
            opinion="Desde el momento de la denuncia, contestaron todo rápidamente. Me
              explicaron todo de muy buena forma. Muy amables, los sigo eligiendo."
          />
          <Card
            imagen="/images/home/estrellas/slider/adriana.png"
            nombre="Adriana Velasco"
            estrellas="5"
            opinion="Agradecida por la rapidez en resolver el siniestro y el pago, así como por la excelente atención de Adriana y el sector correspondiente. 
            Un seguro totalmente confiable y dedicado, algo poco común hoy en día. 
            RECOMENDABLE 100%. MUCHAS GRACIAS POR TODO!!!"
          />
          <Card
            imagen="/images//home/estrellas/slider/Juan.png"
            nombre="Juan Tántera"
            estrellas="5"
            opinion="Desde el inicio del trámite, en donde me asesoraron en forma concreta y rápida, hasta el cobro del mismo el servicio fue excelente

            No solo por la efectividad de todo, sino por la simpleza y velocidad en la que todo aconteció. Me fueron informando paso a paso cómo hacer y en qué estado estaba el trámite."
          />
          <Card
            imagen="/images/home/estrellas/slider/Micaela.png"
            nombre="Micaela Laura"
            estrellas="5"
            opinion="Excelente siempre me responden en tiempo y forma, Vanesa una genia!
            El seguro me cubrió y respondió siempre muy agradecida lo recomiendo el 100 x 💯💪"
          />
        </Slider>
      </section>
    </Container>
  );
}
