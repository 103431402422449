import React, { useState, useRef, useEffect } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { BsArrowLeft } from "react-icons/bs";
import { BiFolder, BiUser, BiCreditCard, BiData } from "react-icons/bi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import "material-symbols";
import "react-credit-cards/es/styles-compiled.css";
import valid from "card-validator";
import NumberFormat from "react-number-format";
import axios from "axios";
import Switch from "react-switch";
import dayjs from "dayjs";
// Componentes
import Marcador from "../marcador/index";
import InputFile from "./inputFile";
import InputPhoto from "./inputPhoto";
import InputVideo from "./inputVideo";
import Tarjeta from "./tarjeta/index";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useFile } from "../../../hooks/useFile";
import { useLoading } from "../../../hooks/useLoading";
import { useSocket } from "../../../context/SocketProvider";
// Utils
import { contrataForm } from "../../../utils/formInitialValues";
import { contrataReglas } from "../../../utils/formRules";
import url from "../../../utils/url";
// Services
import { postearArchivoAutogestion } from "../../../services/autogestion";
import { PRODUCTOS_ID } from "../../../constants/productos";

export default function Contrata({
  prevStep,
  nextStep,
  planSelected,
  cotizacion,
  bicicleteria,
}) {
  const socket = useSocket();
  const inputsRef = useRef({});

  // Estado para el switch de accesorios
  const [tieneAccesorios, setTieneAccesorios] = useState(false);

  // Función para cambiar el estado de los accesorios con el switch
  const handleAccesorios = () => {
    setTieneAccesorios(!tieneAccesorios);
  };

  // Fechas para la tarjeta
  const fecha = new Date();
  const obtenerMesActual = fecha.getMonth() + 1;
  const mesActual =
    obtenerMesActual.toString().length < 2
      ? "0" + obtenerMesActual
      : obtenerMesActual;
  const añoActual = fecha.getFullYear();

  // Estado para determinar la tab seleccionada de autogestión
  const [tab, setTab] = useState("factura");

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors,
    handleChangeExternal,
  } = useForm(handleSubmit, contrataReglas, contrataForm, {
    compania: planSelected.nombre_compania,
    tieneAccesorios: tieneAccesorios,
    tab: tab,
  });
  const { loading, initiateLoading } = useLoading();
  const { sendFiles } = useFile(handleChangeExternal);

  useScrollTop();

  // Tarjeta
  const handleInputFocus = (e) => {};

  async function handleSubmit() {
    initiateLoading();

    // Archivos Autogestión
    const filtrarArchivos = Object.entries(inputValues).filter(
      ([key, value]) => key.includes("archivo") && value !== ""
    );

    const subirArchivos = await Promise.all(
      filtrarArchivos.map(async ([key, value]) => {
        const filePath = await sendFiles(value);
        if (filePath) {
          const archivoAutogestion = {
            id_operacion: cotizacion.numero,
            url: filePath[0].path,
            nombre_campo: key,
            id_producto: planSelected.id_producto,
            nombre_producto: localStorage.getItem("segurobici-vehiculo"),
            estado: 1,
          };
          const autogestion = await postearArchivoAutogestion(
            archivoAutogestion
          );
          return autogestion.data;
        }
      })
    );

    /*
    if (inputValues.linkMercadoLibre) {
      const archivoAutogestion = {
        id_operacion: cotizacion.numero,
        url: inputValues.linkMercadoLibre,
        nombre_campo: "linkMercadoLibre",
        id_producto: planSelected.id_producto,
        nombre_producto: localStorage.getItem("segurobici-vehiculo"),
        estado: 1,
      };
      await postearArchivoAutogestion(archivoAutogestion);
    }
    */

    let plan;

    switch (planSelected.plan) {
      case "Plan Gold":
        if (planSelected.nombre_compania === "La Segunda") {
          plan = "segunda_gold";
        } else {
          plan = "gold";
        }
        break;
      case "Plan Platinum":
        plan = "platinum";
        break;
      case "Plan Standard":
        if (
          planSelected.nombre_compania === "La Segunda" &&
          planSelected.tipo !== "electrica"
        ) {
          plan = "segunda_standard";
        } else if (
          planSelected.nombre_compania === "La Segunda" &&
          planSelected.tipo === "electrica"
        ) {
          plan = "segunda_electrica";
        } else {
          plan = "standard";
        }
        break;
      case "Plan ATM":
        plan = "atm";
        break;
      case "Plan Orbis":
        plan = "orbis";
        break;
      case "Plan Low":
        plan = "low_cost";
        break;
    }

    let tarjeta;
    let validNumber;
    if (inputValues.formaDePago === "Tarjeta de credito") {
      validNumber = valid.number(inputValues.numeroTarjeta);
      tarjeta = validNumber.card.niceType;
    }
    let valor_cuota = planSelected.precio;
    valor_cuota = valor_cuota.slice(1);
    const data = {
      id_operacion: cotizacion.numero,
      nombre: `${inputValues.nombre} ${inputValues.apellido}`,
      email: inputValues.email,
      tel: inputValues.telefono,
      localidad: inputValues.localidad,
      vehiculo: planSelected.id_producto,
      fch_nacimiento: "",
      cuit: inputValues.cuitDNI,
      postal: inputValues.codigo_postal,
      landing: planSelected.tipo,
      marca: inputValues.marca,
      modelo: inputValues.modelo,
      rodado: inputValues.rodado,
      color: inputValues.color,
      material: inputValues.material,
      transmision: inputValues.transmision,
      num_serie: inputValues.inputNumeroSerie,
      tam_cuadro: inputValues.tamañoCuadro,
      accesorios: inputValues.accesorios,
      link_mercadolibre: inputValues.linkMercadoLibre,
      valor: inputValues.suma_asegurada,
      bicicleteria: planSelected.tipo === "bicicleterias" ? bicicleteria : "",
      plan: plan,
      forma_pago:
        inputValues.formaDePago === "Tarjeta de credito"
          ? 1
          : inputValues.formaDePago === "Transferencia bancaria"
          ? 2
          : 3,
      tipo_pago: tarjeta ? tarjeta : "",
      numero_tarjeta: inputValues.numeroTarjeta,
      vto_tarjeta: inputValues.vencimiento,
      numero_cbu: inputValues.cbu,
      tipo: planSelected.tipo,
      nombre_titular: inputValues.titular,
      dni_tarjeta: inputValues.dniTitular,
      cuota: valor_cuota,
      bancoEmisor: inputValues.bancoEmisor,
      valor_contratado: planSelected.precio,
      compania: planSelected.nombre_compania,
      archivos: subirArchivos,
    };

    const producto = PRODUCTOS_ID.find(prod => prod.id === planSelected.id_producto)
    
    //const post = await axios.post(`${url}/api/insert_cotizacion`, data);
    const post = await axios.post(`${url}/api/contratacion`, data);
    if (post.status === 200) {
      const notificacion = {
        url_pagina: "/Autogestion",
        nombre_icono: "autogestion",
        nombre_pagina: "Autogestión",
        notificacion: `Se realizó una contratación en Seguro Bici: ${
          planSelected.nombre_compania
        } - ${planSelected.plan} - ${
          producto.nombre.charAt(0).toUpperCase() +
          producto.nombre.slice(1).toLowerCase().replace("_", " ")
        } - ${inputValues.nombre} ${inputValues.apellido}`,
        dia: dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        tipo: "autogestión"
      }
      socket.emit("notificacion-crm", notificacion);
      await axios.post(`${url}/api/notificaciones`, notificacion);
      nextStep();
    }
  }

  // Estado para determinar la tab seleccionada del método de pago
  const [metodoDePago, setMetodoDePago] = useState("credito");

  const handlePaymentMethod = (metodo) => {
    setMetodoDePago(metodo);
    const pago =
      metodo === "credito" ? "Tarjeta de credito" : "Transferencia bancaria";
    handleChangeExternal("formaDePago", pago);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      const keys = Object.keys(formErrors);
      inputsRef.current[keys[0]].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [formErrors]);

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno="hecho"
        pasoDos="hecho"
        pasoTres="haciendo"
        pasosTerminados=""
        titulo="¡Ya elegiste tu seguro!"
      ></Marcador>
      {/* Botón Atras Container */}
      <div className="boton-atras-container opacidad02">
        {/* Botón Atras */}
        <button type="button" onClick={prevStep}>
          <span>
            <BsArrowLeft />
          </span>
          Volvé a elegir
        </button>
      </div>
      {/* Max Width Coberturas Container */}
      <div id="max-width-coberturas-container">
        {/* Coberturas Container */}
        <div className="coberturas-container opacidad02">
          {/* Logo y Plan Container */}
          <div className="logo-plan-container">
            <img
              alt="Logo Seguro"
              src={process.env.PUBLIC_URL + planSelected.logo}
            ></img>
            <p>{planSelected.plan}</p>
          </div>
          {/* Checks Container */}
          <div className="checks-container">
            {planSelected.coberturas.map((cobertura) => {
              return (
                <span className="check">
                  {cobertura.activo ? (
                    <span class="material-symbols-outlined">done</span>
                  ) : (
                    <span id="check-no" class="material-symbols-outlined">
                      close
                    </span>
                  )}
                  <p>{cobertura.cobertura_info?.nombre}</p>
                </span>
              );
            })}
            {/* Precios Container */}
            <div className="precio-container">
              {/* Cuotas Container */}
              <div>
                {planSelected.cuotas == "" ? null : (
                  <p
                    id={planSelected.precio == "" && "cuotas-bold"}
                    className={
                      planSelected.precioAnterior == "" && "cuotas-naranja"
                    }
                  >
                    {planSelected.cuotas}
                  </p>
                )}
                {planSelected.precioAnterior == "" ? null : (
                  <p id="precio-anterior">{planSelected.precioAnterior}</p>
                )}
              </div>
              {/* Precio */}
              {planSelected.precio == "" ? null : (
                <div>
                  {planSelected.oferta == "" ? null : (
                    <p>{planSelected.oferta}</p>
                  )}
                  <p>{planSelected.precio}</p>
                </div>
              )}
              {planSelected.contratando == "" ? null : (
                <div>
                  <p>{planSelected.contratando}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Formulario */}
      <form className="opacidad05">
        {/* Datos Cotización Container */}
        <div id="datos-cotizacion-container">
          {/* Datos Cotización Título */}
          <div id="datos-cotización-titulo">
            <h4>
              <span>
                <BiData></BiData>
              </span>
              Datos de la cotización
            </h4>
          </div>
          {/* Datos Container */}
          <div id="datos-container">
            {/* Suma Asegurada */}
            <div className="dato">
              <p>Suma Asegurada</p>
              <NumberFormat
                value={localStorage.getItem("segurobici-sumaasegurada")}
                className="foo"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value, props) => <p {...props}>{value}</p>}
              />
            </div>
            {/* Plan Seleccionado */}
            <div className="dato">
              <p>Plan</p>
              <p>
                {planSelected.plan == "Plan Low" && "Low Cost"}
                {planSelected.plan == "Plan Orbis" && "Orbis"}
                {planSelected.plan == "Plan ATM" && "ATM"}
                {planSelected.plan == "Plan Standard" && "Standard"}
                {planSelected.plan == "Plan Gold" && "Gold"}
                {planSelected.plan == "Plan Platinum" && "Platinum"}
              </p>
            </div>
            {/* Tipo de vehículo */}
            <div className="dato" id="dato-vehiculo">
              <p>Tipo de vehículo</p>
              <p>
                {localStorage.getItem("segurobici-vehiculo") == "bicicleta" &&
                  "Bicicleta"}
                {localStorage.getItem("segurobici-vehiculo") ==
                  "bicicleta (delivery)" && "Bicicleta (Delivery)"}
                {localStorage.getItem("segurobici-vehiculo") ==
                  "bicicleta electrica" && "Bicicleta eléctrica"}
                {localStorage.getItem("segurobici-vehiculo") ==
                  "monopatin electrico" && "Monopatín eléctrico"}
              </p>
            </div>
            {/* Provincia */}
            <div className="dato" id="dato-localidad">
              <p>Provincia</p>
              <p>{localStorage.getItem("segurobici-provincia")}</p>
            </div>
          </div>
        </div>
        {/* Datos Personales Container */}
        <div id="datos-personales-container">
          {/* Datos Personales Título */}
          <div id="datos-personales-titulo">
            <h4>
              <span>
                <BiUser></BiUser>
              </span>
              Datos personales
            </h4>
            <p>Contanos de vos.</p>
          </div>
          {/* Input Nombre */}
          <div className="input-container" id="input-nombre-container">
            <label className="input-label" for="input-nombre">
              Nombre
            </label>
            <input
              type="text"
              id="input-nombre"
              name="nombre"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.nombre}
              className={formErrors.nombre == null ? "" : "input-error"}
              placeholder="Nombre"
              ref={(element) => (inputsRef.current["nombre"] = element)}
            ></input>
            {formErrors.nombre && <span>{formErrors.nombre}</span>}
          </div>
          {/* Input Apellido */}
          <div className="input-container" id="input-apellido-container">
            <label className="input-label" for="input-apellido">
              Apellido
            </label>
            <input
              type="text"
              id="input-apellido"
              name="apellido"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.apellido}
              className={formErrors.apellido == null ? "" : "input-error"}
              placeholder="Apellido"
              ref={(element) => (inputsRef.current["apellido"] = element)}
            ></input>
            {formErrors.apellido && <span>{formErrors.apellido}</span>}
          </div>
          {/* Input CUIT o DNI */}
          <div className="input-container" id="input-cuit-dni-container">
            <label className="input-label" for="input-cuit-dni">
              DNI o CUIT
            </label>
            <input
              type="number"
              id="input-cuit-dni"
              name="cuitDNI"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.cuitDNI}
              className={formErrors.cuitDNI == null ? "" : "input-error"}
              placeholder="DNI o CUIT"
              ref={(element) => (inputsRef.current["cuitDNI"] = element)}
            ></input>
            {formErrors.cuitDNI && <span>{formErrors.cuitDNI}</span>}
          </div>
          {/* Input Código Postal */}
          <div className="input-container" id="input-codigo-postal-container">
            <label className="input-label" for="input-codigo-postal">
              Cód. postal
            </label>
            <input
              type="number"
              id="input-codigo-postal"
              name="codigo_postal"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.codigo_postal}
              className={formErrors.codigo_postal == null ? "" : "input-error"}
              placeholder="Cód. postal"
              ref={(element) => (inputsRef.current["codigo_postal"] = element)}
            ></input>
            {formErrors.codigo_postal && (
              <span>{formErrors.codigo_postal}</span>
            )}
          </div>
          {/* Input Email */}
          <div className="input-container" id="input-email-container">
            <label className="input-label" for="input-email">
              Email
            </label>
            <input
              type="email"
              id="input-email"
              name="email"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.email}
              className={formErrors.email == null ? "" : "input-error"}
              placeholder="Email"
              ref={(element) => (inputsRef.current["email"] = element)}
            ></input>
            {formErrors.email && <span>{formErrors.email}</span>}
          </div>
          {/* Input Cód. Área */}
          <div className="input-container" id="input-codigo-area-container">
            <label className="input-label" for="input-codigo-area">
              C. Área
            </label>
            <input
              type="number"
              id="input-codigo-area"
              name="codigo_area"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.codigo_area}
              className={formErrors.codigo_area == null ? "" : "input-error"}
              placeholder="C. Área"
              ref={(element) => (inputsRef.current["codigo_area"] = element)}
            ></input>
            {formErrors.codigo_area && <span>{formErrors.codigo_area}</span>}
          </div>
          {/* Input Teléfono */}
          <div className="input-container" id="input-telefono-container">
            <label className="input-label" for="input-telefono">
              Teléfono
            </label>
            <input
              type="number"
              id="input-telefono"
              name="telefono"
              onChange={handleChange}
              onFocus={handleChange}
              onBlur={handleChange}
              value={inputValues.telefono}
              className={formErrors.telefono == null ? "" : "input-error"}
              placeholder="Teléfono"
              ref={(element) => (inputsRef.current["telefono"] = element)}
            ></input>
            {formErrors.telefono && <span>{formErrors.telefono}</span>}
          </div>
        </div>
        {/* Autogestión */}
        <div id="autogestion-container">
          {/* Autogestión Título */}
          <div id="autogestion-titulo">
            <h4>
              <span>
                <BiFolder></BiFolder>
              </span>
              Autogestión
            </h4>
            <p>
              Vamos a necesitar que subas algunos datos y archivos para validar
              tu vehículo.
            </p>
            {localStorage.getItem("segurobici-vehiculo") == "bicicleta" && (
              <p>
                Estas coberturas son únicamente para uso particular. ¿Trabajas
                con tu bici?{" "}
                <a href="/seguro-de-bicicleta-comercial">
                  Cotizá una cobertura especial para uso comercial.
                </a>
              </p>
            )}
          </div>
          {/* Autogestión Marca Modelo */}
          <div id="autogestion-marca-modelo">
            {/* Marca */}
            <div className="input-container" id="input-marca-container">
              <label className="input-label" for="inputMarca">
                Marca
              </label>
              <input
                type="text"
                id="inputMarca"
                name="marca"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.marca}
                className={formErrors.marca == null ? "" : "input-error"}
                placeholder="Marca"
                ref={(element) => (inputsRef.current["marca"] = element)}
              ></input>
              {formErrors.marca && <span>{formErrors.marca}</span>}
            </div>
            {/* Modelo */}
            <div className="input-container" id="input-modelo-container">
              <label className="input-label" for="inputModelo">
                Modelo
              </label>
              <input
                type="text"
                id="inputModelo"
                name="modelo"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.modelo}
                className={formErrors.modelo == null ? "" : "input-error"}
                placeholder="Modelo"
                ref={(element) => (inputsRef.current["modelo"] = element)}
              ></input>
              {formErrors.modelo && <span>{formErrors.modelo}</span>}
            </div>
            {/* Rodado */}
            <div className="input-container" id="input-rodado-container">
              <label className="input-label" for="inputRodado">
                Rodado
              </label>
              <input
                type="number"
                id="inputRodado"
                name="rodado"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.rodado}
                className={formErrors.rodado == null ? "" : "input-error"}
                placeholder="Rodado"
                ref={(element) => (inputsRef.current["rodado"] = element)}
              ></input>
              {formErrors.rodado && <span>{formErrors.rodado}</span>}
            </div>
            {/* Color */}
            <div className="input-container" id="input-color-container">
              <label className="input-label" for="inputColor">
                Color
              </label>
              <input
                type="text"
                id="inputColor"
                name="color"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.color}
                className={formErrors.color == null ? "" : "input-error"}
                placeholder="Color"
                ref={(element) => (inputsRef.current["color"] = element)}
              ></input>
              {formErrors.color && <span>{formErrors.color}</span>}
            </div>
            {/* Material */}
            <div className="select-container" id="select-material-container">
              <label className="input-label" for="selectMaterial">
                Material
              </label>
              <select
                id="selectMaterial"
                name="material"
                value={inputValues.material}
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                className={formErrors.material == null ? "" : "input-error"}
                placerholder="Material"
                ref={(element) => (inputsRef.current["material"] = element)}
              >
                <option value="" disabled selected hidden>
                  Material
                </option>
                <option value="Aluminio">Aluminio</option>
                <option value="Acero">Acero</option>
                <option value="Carbono">Carbono</option>
              </select>
              <div className="flecha-select-container">
                <i></i>
              </div>
              {formErrors.material && <span>{formErrors.material}</span>}
            </div>
            {/* Transmisión */}
            <div className="input-container" id="input-transmision-container">
              <label className="input-label" for="inputTransmision">
                Marca y mod. de transmisión
              </label>
              <input
                type="text"
                id="inputTransmision"
                name="transmision"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.transmision}
                className={formErrors.transmision == null ? "" : "input-error"}
                placeholder="Marca y mod. de transmisión"
                ref={(element) => (inputsRef.current["transmision"] = element)}
              ></input>
              {formErrors.transmision && <span>{formErrors.transmision}</span>}
            </div>
            {/* Número Serie */}
            <div className="input-container" id="input-numero-serie-container">
              <label className="input-label" for="inputNumeroSerie">
                Número de serie
              </label>
              <input
                type="text"
                id="inputNumeroSerie"
                name="inputNumeroSerie"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.inputNumeroSerie}
                className={
                  formErrors.inputNumeroSerie == null ? "" : "input-error"
                }
                placeholder="Número de serie"
                ref={(element) =>
                  (inputsRef.current["inputNumeroSerie"] = element)
                }
              ></input>
              {formErrors.inputNumeroSerie && (
                <span>{formErrors.inputNumeroSerie}</span>
              )}
            </div>
            {/* Tamaño Cuadro (Solo si la comapañia es Prudencia Seguros) */}
            {planSelected.nombre_compania == "Prudencia Seguros" && (
              <div
                className="select-container"
                id="select-tamaño-cuadro-container"
              >
                <label className="input-label" for="tamañoCuadro">
                  Tamaño del cuadro
                </label>
                <select
                  id="tamañoCuadro"
                  name="tamañoCuadro"
                  value={inputValues.tamañoCuadro}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={
                    formErrors.tamañoCuadro == null ? "" : "input-error"
                  }
                  placerholder="Tamaño del cuadro"
                  ref={(element) =>
                    (inputsRef.current["tamañoCuadro"] = element)
                  }
                >
                  <option value="" disabled selected hidden>
                    Tamaño del cuadro
                  </option>
                  <option value="Chico">Chico</option>
                  <option value="Mediano">Mediano</option>
                  <option value="Grande">Grande</option>
                </select>
                <div className="flecha-select-container">
                  <i></i>
                </div>
                {formErrors.tamañoCuadro && (
                  <span>{formErrors.tamañoCuadro}</span>
                )}
              </div>
            )}
          </div>
          {/* Autogestión Accesorios */}
          <div id="autogestion-accesorios">
            <div id="texto-switch-container">
              <p>¿Tiene accesorios agregados?</p>
              <Switch
                onChange={handleAccesorios}
                checked={tieneAccesorios}
                uncheckedIcon={false}
                checkedIcon={false}
                offColor="#f6f6f6"
                onColor="#0DB27F"
                activeBoxShadow="0 0 0px 0px #fff"
                className="switch-accesorios"
              />
            </div>
            {/* Accesorios */}
            {tieneAccesorios == true && (
              <div className="input-container" id="input-accesorios-container">
                <label className="input-label" for="inputAccesorios">
                  Lista de accesorios
                </label>
                <input
                  type="text"
                  id="inputAccesorios"
                  name="accesorios"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.accesorios}
                  className={formErrors.accesorios == null ? "" : "input-error"}
                  placeholder="Lista de accesorios"
                  ref={(element) => (inputsRef.current["accesorios"] = element)}
                ></input>
                {formErrors.accesorios && <span>{formErrors.accesorios}</span>}
              </div>
            )}
          </div>
          {/* Autogestión Foto Video Container */}
          <div id="autogestion-foto-video-container">
            {/* Foto del vehículo */}
            <InputPhoto
              handleChangeExternal={handleChangeExternal}
              archivo={inputValues.archivo_bicicleta}
              texto="Foto del vehículo"
              nombre="archivo_bicicleta"
              label="Foto del vehículo"
              id="input-foto-bici"
              tituloInfo="Requisitos"
              requisitos="Vehículo completo, con una hoja sobre la rueda con la fecha actual
            manuescrita."
              peso="Hasta 2mb máx."
              imagenModal="/images/pasos/contrata/ejemplos/EjemploBiciConFecha.svg"
              tituloModal="Foto de bicicleta con fecha actual"
              textoModal="Necesitamos una fotografía de la bicicleta junto con una hoja adherida que muestre claramente la fecha actual en la que se envía la cotización. Utiliza la imagen de ejemplo como guía para la presentación."
              error={formErrors.archivo_bicicleta}
              ref={(element) =>
                (inputsRef.current["archivo_bicicleta"] = element)
              }
            ></InputPhoto>
            {/* Foto del número de serie */}
            <InputPhoto
              handleChangeExternal={handleChangeExternal}
              archivo={inputValues.archivo_num_serie_bicicleta}
              texto="Foto del núm. de serie"
              nombre="archivo_num_serie_bicicleta"
              label="Foto del núm. de serie"
              id="input-foto-serie"
              tituloInfo="¿Dónde?"
              requisitos="Suele estar en la parte de abajo de los pedalos, generalmente grabado."
              peso="Hasta 2mb máx."
              imagenModal="/images/pasos/contrata/ejemplos/EjemploNumSerie.svg"
              tituloModal="Foto del número de serie de Bicicleta"
              textoModal="Necesitamos una fotografía donde sea visible el número de serie de la bicicleta. Por lo general, este número se encuentra debajo de los pedales, tal como se muestra en la imagen redonda de ejemplo. Asegúrate de capturar la imagen de manera similar a la ilustración redonda de ejemplo."
              error={formErrors.archivo_num_serie_bicicleta}
              ref={(element) =>
                (inputsRef.current["archivo_num_serie_bicicleta"] = element)
              }
            ></InputPhoto>
            {/* Foto del documento */}
            <InputPhoto
              handleChangeExternal={handleChangeExternal}
              archivo={inputValues.archivo_frente_dni}
              texto="Foto del DNI"
              nombre="archivo_frente_dni"
              label="Foto del DNI"
              id="input-foto-dni"
              tituloInfo="Solo el frente"
              requisitos="Solo necesitamos el frente de tu DNI para validar tu identidad."
              peso="Hasta 2mb máx."
              imagenModal="/images/pasos/contrata/ejemplos/dni.svg"
              tituloModal="Foto del frente de tu DNI"
              textoModal="Necesitamos una foto del frente de tu documento de identidad para poder validar tus datos. Sacá la foto con buena iluminación, sin sombras en una superficie plana y procura que todos los datos se vean con clariadad."
              error={formErrors.archivo_frente_dni}
              ref={(element) =>
                (inputsRef.current["archivo_frente_dni"] = element)
              }
            ></InputPhoto>
            {/* Factura de los accesorios solamente si tiene los mismos */}
            {tieneAccesorios == true && (
              <InputFile
                handleChangeExternal={handleChangeExternal}
                archivo={inputValues.archivo_factura_accesorios}
                texto="Factura de accesorios"
                nombre="archivo_factura_accesorios"
                label="Factura de accesorios"
                id="input-factura-accesorios"
                tituloInfo="¿Tenés más de una factura?"
                requisitos={[
                  "Si tenes más de una factura, unificalas en un solo archivo PDF.",
                  <a
                    href="https://www.ilovepdf.com/es/unir_pdf"
                    target="_blank"
                  >
                    Unir ahora
                  </a>,
                ]}
                peso="Hasta 2mb máx."
                imagenModal="/images/pasos/contrata/ejemplos/accesorios.webp"
                tituloModal="Factura de accesorios"
                textoModal={
                  <p className="texto-container">
                    Necesitamos las facturas de todos los accesorios en formato
                    PDF. En caso de tener más de una factura, puedes unificarlas
                    a través del siguiente enlace.
                    <a
                      href="https://www.ilovepdf.com/es/unir_pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Click Aquí
                    </a>
                    .
                  </p>
                }
                error={formErrors.archivo_factura_accesorios}
                ref={(element) =>
                  (inputsRef.current["archivo_factura_accesorios"] = element)
                }
              ></InputFile>
            )}
            {/* Video del vehículo (solo si el valor asegurado es de $500.000 para arriba) */}
            {localStorage.getItem("segurobici-sumaasegurada") >= 500000 && (
              <InputVideo
                handleChangeExternal={handleChangeExternal}
                archivo={inputValues.archivo_video}
                texto="Video de la bicicleta"
                nombre="archivo_video"
                label="Video de la bicicleta"
                id="input-video-bici"
                tituloInfo="Requisitos"
                requisitos="Video del vehículo completo rotando por completo alrededor del mismo."
                peso="Hasta 5mb máx."
                error={formErrors.archivo_video}
                ref={(element) =>
                  (inputsRef.current["archivo_video"] = element)
                }
              ></InputVideo>
            )}
          </div>
          {/* Autogestión Archivos */}
          <div id="autogestion-archivos">
            <p id="texto-factura">
              Subí la factura de tu vehículo, un presupuesto del mismo o una
              referencia en MercadoLibre.
            </p>
            {/* Tabs */}
            <div id="tabs-container">
              <a
                onClick={() => setTab("factura")}
                className={tab == "factura" && "tab-activa"}
              >
                <span></span>
                Factura
              </a>
              <a
                onClick={() => setTab("presupuesto")}
                className={tab == "presupuesto" && "tab-activa"}
              >
                <span></span>
                Presupuesto
              </a>
              <a
                onClick={() => setTab("meli")}
                className={tab == "meli" && "tab-activa"}
              >
                <img
                  alt="Logo de MercadoLibre"
                  src="/images/logos/mercadolibre.svg"
                ></img>
              </a>
            </div>
            {/* Archivo Valor Container */}
            <div id="archivo-valor-container">
              {tab == "factura" ? (
                // Factura del vehículo
                <InputFile
                  handleChangeExternal={handleChangeExternal}
                  archivo={inputValues.archivo_factura_bicicleta}
                  texto="Factura de compra"
                  nombre="archivo_factura_bicicleta"
                  label="Factura de compra"
                  id="input-factura"
                  tituloInfo="Requisitos"
                  requisitos="Se aceptan facturas solamente de hasta 3 meses desde su emisión."
                  peso="Hasta 2mb máx."
                  imagenModal="/images/pasos/contrata/ejemplos/EjemploFactura.svg"
                  tituloModal="Factura de compra"
                  textoModal="Se requiere la factura de compra del vehículo en formato PDF con una antigüedad no superior a 3 meses. En caso contrario, puede proceder a gestionar un presupuesto."
                  error={formErrors.archivo_factura_bicicleta}
                  ref={(element) =>
                    (inputsRef.current["archivo_factura_bicicleta"] = element)
                  }
                ></InputFile>
              ) : tab == "presupuesto" ? (
                // Presupuesto de la bicicleta
                <InputFile
                  handleChangeExternal={handleChangeExternal}
                  archivo={inputValues.archivo_presupuesto_bicicleta}
                  texto="Presupuesto"
                  nombre="archivo_presupuesto_bicicleta"
                  label="Presupuesto"
                  id="input-presupuesto"
                  tituloInfo="Requisitos"
                  requisitos="Se aceptan presupuestos solamente de hasta 3 meses desde su emisión."
                  peso="Hasta 2mb máx."
                  imagenModal="/images/pasos/contrata/ejemplos/EjemploCotizacion.svg"
                  tituloModal="Presupuesto del vehículo"
                  textoModal="Se requiere el presupuesto de no mas de tres meses del vehículo al que se quiere asegurar en formato PDF"
                  error={formErrors.archivo_presupuesto_bicicleta}
                  ref={(element) =>
                    (inputsRef.current["archivo_presupuesto_bicicleta"] =
                      element)
                  }
                ></InputFile>
              ) : (
                // Input Link MercadoLibre
                <div
                  className="input-container"
                  id="input-mercadolibre-container"
                >
                  <label className="input-label" for="input-mercadolibre">
                    Link de Mercado Libre (*)
                  </label>
                  <input
                    type="text"
                    id="input-mercadolibre"
                    name="linkMercadoLibre"
                    onChange={handleChange}
                    onFocus={handleChange}
                    onBlur={handleChange}
                    value={inputValues.linkMercadoLibre}
                    className={
                      formErrors.linkMercadoLibre == null ? "" : "input-error"
                    }
                    placeholder="Link de la publicación"
                    ref={(element) =>
                      (inputsRef.current["linkMercadoLibre"] = element)
                    }
                  ></input>
                  <div><span className="info-input">(*) No debe tener cuotas sin interés</span></div>
                  {formErrors.linkMercadoLibre && (
                    <span>{formErrors.linkMercadoLibre}</span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Datos de Pago Container */}
        <div id="datos-pago-container">
          {/* Datos de Pago Título */}
          <div id="datos-de-pago-titulo">
            <h4>
              <span>
                <BiCreditCard></BiCreditCard>
              </span>
              Método de pago
            </h4>
            <p>Seleccioná tu metodo de pago preferido.</p>
          </div>
          {/* Tabs */}
          <div id="tabs-container">
            <a
              onClick={() => handlePaymentMethod("credito")}
              className={metodoDePago == "credito" && "tab-activa"}
            >
              <span></span>
              Tarjeta de credito
            </a>
            <a
              onClick={() => handlePaymentMethod("debito")}
              className={metodoDePago == "debito" && "tab-activa"}
            >
              <span></span>
              Débito automático
            </a>
          </div>
          {/* Pago Transferencia Bancaria */}
          {metodoDePago == "debito" && (
            <div className="input-container" id="input-cbu-container">
              <label className="input-label" for="inputCBU">
                CBU
              </label>
              <input
                type="number"
                id="inputCBU"
                name="cbu"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.cbu}
                className={formErrors.cbu == null ? "" : "input-error"}
                placeholder="CBU"
                ref={(element) => (inputsRef.current["cbu"] = element)}
              ></input>
              {formErrors.cbu && <span>{formErrors.cbu}</span>}
            </div>
          )}
          {/* Pago Tarjeta Container */}
          {metodoDePago == "credito" && (
            <div id="pago-tarjeta-container">
              <div id="datos-tarjeta-credito">
                {/* Input Número de tarjeta */}
                <div
                  className="input-container"
                  id="input-numero-tarjeta-container"
                >
                  <label className="input-label" for="input-numero-tarjeta">
                    Número de tarjeta
                  </label>
                  <input
                    type="number"
                    id="input-numero-tarjeta"
                    name="numeroTarjeta"
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                    onBlur={handleChange}
                    value={inputValues.numeroTarjeta}
                    className={
                      formErrors.numeroTarjeta == null ? "" : "input-error"
                    }
                    placeholder="Número de tarjeta"
                    ref={(element) =>
                      (inputsRef.current["numeroTarjeta"] = element)
                    }
                  ></input>
                  {formErrors.numeroTarjeta && (
                    <span>{formErrors.numeroTarjeta}</span>
                  )}
                </div>
                {/* Input Bnaco Emisor */}
                <div
                  className="input-container"
                  id="input-banco-emisor-container"
                >
                  <label className="input-label" for="inputBancoEmisor">
                    Banco emisor
                  </label>
                  <input
                    type="text"
                    id="inputBancoEmisor"
                    name="bancoEmisor"
                    onChange={handleChange}
                    onFocus={handleChange}
                    onBlur={handleChange}
                    value={inputValues.bancoEmisor}
                    className={
                      formErrors.bancoEmisor == null ? "" : "input-error"
                    }
                    placeholder="Banco emisor"
                    ref={(element) =>
                      (inputsRef.current["bancoEmisor"] = element)
                    }
                  ></input>
                  {formErrors.bancoEmisor && (
                    <span>{formErrors.bancoEmisor}</span>
                  )}
                </div>
                {/* Input Vencimiento */}
                <div
                  className="input-container"
                  id="input-vencimiento-container"
                >
                  <label className="input-label" for="inputVencimiento">
                    Vencimiento
                  </label>
                  <input
                    type="month"
                    // De esta manera no deja poner tarjetas vencidas y se updatea solo
                    min={añoActual + "-" + mesActual}
                    max={añoActual + 8 + "-" + mesActual}
                    id="inputVencimiento"
                    name="vencimiento"
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                    onBlur={handleChange}
                    value={inputValues.vencimiento}
                    className={
                      formErrors.vencimiento == null ? "" : "input-error"
                    }
                    placeholder="Vencimiento"
                    ref={(element) =>
                      (inputsRef.current["vencimiento"] = element)
                    }
                  ></input>
                  {formErrors.vencimiento && (
                    <span>{formErrors.vencimiento}</span>
                  )}
                </div>
                {/* Input Titular */}
                <div className="input-container" id="input-titular-container">
                  <label className="input-label" for="inputTitular">
                    Titular de la tarjeta
                  </label>
                  <input
                    type="text"
                    id="inputTitular"
                    name="titular"
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                    onBlur={handleChange}
                    value={inputValues.titular}
                    className={formErrors.titular == null ? "" : "input-error"}
                    placeholder="Titular de la tarjeta"
                    ref={(element) => (inputsRef.current["titular"] = element)}
                  ></input>
                  {formErrors.titular && <span>{formErrors.titular}</span>}
                </div>
                {/* Input DNI Titular */}
                <div
                  className="input-container"
                  id="input-dni-titular-container"
                >
                  <label className="input-label" for="input-DNI-titular">
                    DNI del titular
                  </label>
                  <input
                    type="number"
                    id="input-DNI-titular"
                    name="dniTitular"
                    onChange={handleChange}
                    onFocus={handleChange}
                    onBlur={handleChange}
                    value={inputValues.dniTitular}
                    className={
                      formErrors.dniTitular == null ? "" : "input-error"
                    }
                    placeholder="DNI del titular"
                    ref={(element) =>
                      (inputsRef.current["dniTitular"] = element)
                    }
                  ></input>
                  {formErrors.dniTitular && (
                    <span>{formErrors.dniTitular}</span>
                  )}
                </div>
              </div>
              {/* Tarjeta Credito Container */}
              <div id="tarjeta-credito-container">
                <Tarjeta
                  numero={inputValues.numeroTarjeta}
                  titular={inputValues.titular}
                  vencimiento={inputValues.vencimiento}
                  cvc={inputValues.cvc}
                  focused={inputValues.focus}
                />
              </div>
            </div>
          )}
        </div>
        {/* Botón Contratá Container */}
        <div id="boton-contrata-container">
          <button
            type="button"
            id="boton-contrata"
            onClick={handleValidation}
            disabled={loading}
          >
            {loading ? "Contratando..." : "Contratar"}
            {!loading && (
              <span>
                <HiOutlineArrowLongRight />
              </span>
            )}
          </button>
        </div>
      </form>
    </Container>
  );
}
