import Elegi from "../elegi/index";
import Contrata from "../contrata/index";
import Contrataste from "../contrataste";
import Footer from "../../footer";
// Custom Hooks
import { useSwitch } from "../../../hooks/useSwitch";
import { usePlanes } from "../../../hooks/usePlanes";
// Utils
import { stepForwardMarketing, stepBackwardsMarketing } from "../../../utils/marketing"

export default function PasosSwitch() {

    const { step, prevStep, nextStep } = useSwitch(stepBackwardsMarketing, stepForwardMarketing)
    const { planes, cotizacion, planSelected, handlePlan, bicicleteria } = usePlanes()

    switch (step) {
        case 1:
          return (
            <>
              <Elegi
                nextStep={nextStep}
                planSelected={handlePlan}
                planes={planes}
                cotizacion={cotizacion}
              ></Elegi>
              <Footer marcas=""></Footer>
            </>
          );
        case 2:
          return (
            <>
              <Contrata
                prevStep={prevStep}
                nextStep={nextStep}
                planSelected={planSelected}
                cotizacion={cotizacion}
                bicicleteria={bicicleteria}
              ></Contrata>
              <Footer marcas=""></Footer>
            </>
          );
        case 3:
          return (
            <>
              <Contrataste
                prevStep={prevStep}
                planSelected={planSelected}
                cotizacion={cotizacion}
              ></Contrataste>
              <Footer marcas="active"></Footer>
            </>
          );
        default:
         return 
      }
}