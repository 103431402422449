import { useEffect, useState } from "react";
import { actualizarValoresCotizacion, obtenerCotizacion, enviarMailCotizacion } from "../services/cotizaciones";
import { useLocation } from "react-router-dom";

// Temporal
import axios from "axios";
import url from "../utils/url";

export const usePlanes = () => {

  const location = useLocation();
  const id_operacion = new URLSearchParams(location.search).get("op");
  const hash = new URLSearchParams(location.search).get("hash");
  
  const locationPlanes = location?.state?.planes ? location.state.planes : []

  const [planes, setPlanes] = useState(locationPlanes)
  const [cotizacion, setCotizacion] = useState(location?.state ? location.state : {})
  const [planSelected, setPlanSelected] = useState(null)
  const [planesCotizados, setPlanesCotizados] = useState(false)
  const bicicleteria = location.state?.bicicleteria

  console.log(planes, 'estos son los planes')

  const planesFromDb = async () => {
    try {
      const getDataOperacion = await axios.get(
        `${url}/api/operaciones/tablaOperaciones/${id_operacion}`
      );
      localStorage.setItem(
        "segurobici-vehiculo",
        getDataOperacion.data[0].producto
      );
      localStorage.setItem(
        "segurobici-sumaasegurada",
        getDataOperacion.data[0].valor_asegurado
      );
      localStorage.setItem(
        "segurobici-provincia",
        getDataOperacion.data[0].provincia
      );
      localStorage.setItem(
        "segurobici-nombre",
        getDataOperacion.data[0].nombre
      );
      localStorage.setItem("segurobici-email", getDataOperacion.data[0].email);

      const getData = await axios.get(`${url}/api/operaciones/${id_operacion}`);
      const planesToSend = {
        planes: getData.data,
      };

      planesToSend.planes.forEach((plan) => {
        plan.precio = "$" + plan.precio;
      });

      setPlanes(planesToSend.planes);
      setCotizacion(planesToSend);
    } catch (error) {
      console.log(error);
    }
    /*
    try {
      const dataOp = await obtenerCotizacion(id_operacion)
      if (dataOp.status === 200) {
        setPlanes(dataOp.data.planes);
        setCotizacion(dataOp.data);
      }
    } catch (error) {
      console.log(error);
    }
    */
  }

  const planesAPI = async () => {
    try {
      if (planesCotizados) return;
      actualizarPlanes(true)
      await actualizarValoresCotizacion(id_operacion, planes)
      await enviarMailCotizacion(id_operacion)
    } catch (error) {
      console.log(error);
    }
  }

  const handlePlan = (plan) => {
    setPlanSelected(plan)
  }

  const actualizarPlanes = (planes) => {
    setPlanesCotizados(planes)
  }

  useEffect(() => {
    if (hash) {
      planesFromDb()
    } else {
      planesAPI()
    }
  }, [])

  return {
    planes, 
    cotizacion, 
    handlePlan,
    planSelected,
    bicicleteria
  }
};
