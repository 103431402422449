import React, { useEffect } from "react";
import Marcador from "../marcador/index";
import { BsCheckLg } from "react-icons/bs";
import Container from "./styled";
import Sound from "react-sound";
import Animacion from "./animacion";
import Timbre from "./timbre.mp3";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useConsulta } from "../../../hooks/useConsulta";

export default function Contrataste({ planSelected, cotizacion }) {
  useScrollTop();
  useConsulta(planSelected, cotizacion);

  useEffect(() => {
    const scriptPixel = document.createElement("script");
    const scriptGoogleAds = document.createElement("script");

    scriptPixel.text = `fbq('track', 'Lead', {})`;
    scriptGoogleAds.text = `gtag('event', 'conversion', {'send_to': 'AW-959485109/n3ZACOqEjNEDELWpwskD'})`;

    document.body.appendChild(scriptPixel);
    document.head.appendChild(scriptGoogleAds);

    return () => {
      document.body.removeChild(scriptPixel);
      document.head.removeChild(scriptGoogleAds);
    };
  }, []);

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno=""
        pasoDos=""
        pasoTres=""
        pasosTerminados={true}
        titulo={"¡Completaste el formulario con éxito!"}
        pasoTresTexto={
          planSelected.precio !== "" && planSelected !== 0
            ? "Contratá"
            : "Consultá"
        }
      ></Marcador>
      {/* Sonido */}
      <Sound
        url={Timbre}
        autoLoad={true}
        playStatus={Sound.status.PLAYING}
        volume={80}
      />
      {/* Contrataste Container */}
      <div className="contrataste-container opacidad04">
        {/* Animación Container */}
        <div id="animacion-container">
          <span></span>
          <Animacion></Animacion>
        </div>
        {/* Texto Agradecimiento */}
        <div className="texto-container">
          {planSelected.precio !== "" && planSelected !== 0 ? (
            <div>
              <p id="texto-contacto">
                A la brevedad nos pondremos en contacto contigo, para informarte
                el inicio de vigencia de la póliza y enviarte la misma.
              </p>
              <p id="texto-contacto">
                ¡Muchas Gracias por confiar en SeguroBici!
              </p>
            </div>
          ) : (
            <div>
              <p id="texto-contacto">
                Nos pondremos en contacto con vos a la brevedad para continuar
                el proceso.
              </p>
              <p id="texto-contacto">
                ¡Muchas Gracias por confiar en SeguroBici!
              </p>
            </div>
          )}

          <p id="texto-contacto" className="texto-agradecimiento">
            Atentamente,{" "}
            <strong className="texto-agradecimiento-area">
              Área de Autogestión de SeguroBici
            </strong>
          </p>
        </div>
        {/* Seguro Container */}
        <div id="seguro-container">
          <img alt="Logo Seguro" src={planSelected.logo}></img>
          <p>{planSelected.seguro}</p>
          <p>{planSelected.plan}</p>
        </div>
      </div>
    </Container>
  );
}
