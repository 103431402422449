import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  padding: 0px 0px 88px 0px;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  border-bottom: ${(props) =>
    props.marcas == "active" ? "1px solid var(--grisClaro);" : "0px"};
  // Puntos de fondo
  background: linear-gradient(
        90deg,
        var(--celeste90) calc(22px - 1.4px),
        transparent 1%
      )
      center / 22px 22px,
    linear-gradient(var(--celeste90) calc(22px - 1.4px), transparent 1%) center /
      22px 22px,
    #98a5be;

  // Max Width Container
  #max-width-container {
    width: 1140px;
    padding: 80px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Tag Container
  #tag-container {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;

    // Tag
    div {
      width: auto;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    // Tag Text
    p {
      width: auto;
      margin: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      color: var(--lila80);
      line-height: 154.7%;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  // Title
  h3 {
    width: 100%;
    margin: 28px 0px 0px 0px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    letter-spacing: -0.015em;
    line-height: 42px;

    // Span
    span {
      font-weight: 500;
      color: var(--naranja100);
    }
  }

  .title {
    display: flex;
    justify-content: center;
  }
  
  // Cards Container
  .carousel {
    width: 100%;
    padding: 0px 0px 0px 0px;
    margin: 80px 0px 0px 0px;
    background-color: transparent;
  }

  .carousel-row {
    display: flex;
    justify-content: center;
  }

    // 1366px Desktop ------------------------------------------------------------------------------------
    @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    #max-width-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 520px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      max-width: 390px;
    }

    // Title Container
    .title-container {
      width: 60%;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    display: none;
  }
`;

export default Container;
